import React from 'react';
import styled from 'styled-components';
// Components
import ProjectBox from '../Elements/ProjectBox';
import FullButton from '../Buttons/FullButton';
// Assets
import ProjectImg1 from '../../assets/img-v1/projects/20160912_103959.jpg';
import ProjectImg2 from '../../assets/img-v1/projects/20171029_174305.jpg';
import ProjectImg3 from '../../assets/img-v1/projects/20171220_171050.jpg';
import ProjectImg4 from '../../assets/img-v1/projects/IMG-20161209-WA0007.jpg';
import ProjectImg5 from '../../assets/img-v1/projects/IMG-20170612-WA0018.jpg';
import ProjectImg6 from '../../assets/img-v1/projects/IMG-20180413-WA0017.jpg';
import AddImage2 from '../../assets/img-v1/projects/IMG-20211221-WA0056.jpg';
import ContactUsButton from '../Buttons/ContactUs';

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => console.log('clicked')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => console.log('clicked')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => console.log('clicked')}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => console.log('clicked')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => console.log('clicked')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Awesome Project"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                action={() => console.log('clicked')}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: '50px 0', width: '200px' }}>
              <FullButton
                title="Load More"
                action={() => console.log('clicked')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">Shivam Art</h4>
              <h2 className="font40 extraBold">
                Crafting Timeless Beauty for Kashi Vishwanath Corridor
              </h2>
              <p className="font12">
                At Shivam Art, we take immense pride in our role in the creation
                of the majestic Kashi Vishwanath Corridor, one of the most
                iconic and revered sites in India. As the leading artisans
                behind the main corridor of this sacred space, our team has
                blended traditional craftsmanship with modern design to create a
                stunning architectural marvel that enhances the spiritual
                experience for millions of devotees. Our dedication to detail,
                precision, and artistry has shaped every corner of the corridor,
                preserving the rich cultural heritage of Varanasi while
                elevating it to new heights of grandeur. From intricate carvings
                to awe-inspiring designs, each element has been thoughtfully
                crafted with respect for the sacred traditions and the vision
                for a brighter, more accessible future.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: '30px 0' }}
              >
                <div style={{ width: '190px' }}>
                  <ContactUsButton />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
